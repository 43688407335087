import callApi from '../../helpers/callApi';
import callFileApi from '../../helpers/callFileApi';

export default {
  // 파트너메뉴 > 렌트요청 콜 > 예약확정 탭의 '확정하기' 버튼 클릭시 사용
  sendBookingConfirmRequest(params) {
    return callApi({
      method: 'POST',
      url: `/v2/external-requests/${params.request_id}/confirm`,
      data: {
        customer_contact: params.customer_contact,
      },
    });
  },
  sendRequest(params) {
    return callApi({
      method: 'POST',
      url: '/v2/self-contract-info',
      data: params,
    });
  },
  checkContact(params) {
    return callApi({
      method: 'POST',
      url: `/v2/self-contract-info/${params.id}/validate-customer-contact`,
      data: {
        customer_contact: params.number,
      },
    });
  },
  setInfo(params) {
    return callApi({
      method: 'POST',
      url: `/v2/self-contract-info/${params.id}`,
      data: {
        customer_contact: params.customer_contact, // required
        customer_name: params.customer_name, // required
        customer_address: params.customer_address, // required
        customer_id_number1: params.customer_id_number1, // required
        customer_driver_license: params.customer_driver_license, // required
        customer_driver_type: params.customer_driver_type, // required
        customer_car_number: params.customer_car_number, // required(insurance)
        customer_car: params.customer_car, // required(insurance)
        insurance_company: params.insurance_company,
        registration_id: params.registration_id,
        customer_license_date: params.customer_license_date, // 면허 취득일
        customer_car_verified: params.customer_car_verified,
        is_customer_license_under_one_year: params.is_customer_license_under_one_year,
        // 추가 운전자 정보
        second_driver_name: params.second_driver_name,
        second_driver_id_number1: params.second_driver_id_number1,
        second_driver_contact: params.second_driver_contact,
        second_driver_address: params.second_driver_address,
        second_driver_type: params.second_driver_type,
        second_driver_license: params.second_driver_license,
        second_driver_license_date: params.second_driver_license_date, // 면허 취득일
        is_second_driver_license_under_one_year: params.is_second_driver_license_under_one_year,
      },
    });
  },
  getRequestList(params) {
    let urlParams = `done=${params.done}&page=${params.page}`;

    if (params.searchValue && params.searchValue.length > 0) {
      urlParams += `&search=${encodeURIComponent(params.searchValue)}`;
    }

    if (params.pending_signature) {
      urlParams += `&pending_signature=${encodeURIComponent(params.pending_signature)}`;
    }

    if (params.type) {
      urlParams += `&type=${params.type}`;
    }

    return callApi({
      method: 'GET',
      url: `/v2/self-contract-info?${urlParams}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookies,
    });
  },
  removeRequest(params) {
    return callApi({
      method: 'POST',
      url: '/self_info/remove_info',
      data: { self_info_id: params.selfInfoId },
    });
  },
  checkExist(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'GET',
      url: `/v2/self-contract-info/${encodeURIComponent(params.id)}/conclude`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  checkPhoneNum(params) {
    return callApi({
      method: 'POST',
      url: `/v2/self-contract-info/${params.id}/validate-customer-contact`,
      data: {
        customer_contact: params.contactNum,
      },
    });
  },
  getContractPdfId(params) {
    return callApi({
      method: 'GET',
      url: `/v2/self-contract-info/${params.id}/contract-info?customer_contact=${params.contactNum}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  updateContractSignature(params) {
    return callApi({
      method: 'POST',
      url: `/v2/self-contract-info/${params.id}/update-signature`,
      data: {
        signature_image: params.signatureImage,
        customer_contact: params.contactNum,
        remote: true,
        disableErrorHandler: params.disableErrorHandler,
      },
    });
  },
  requestSignature(params) {
    return callApi({
      method: 'POST',
      url: `/v2/self-contract-info/${params.self_info_id}/request-signature`,
    });
  },
  getSelfInfoData: (params) => {
    return callApi({
      method: 'GET',
      url: `/v2/public/self-contract-info/${params.id}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  getSelfInfoDataPrivate: (params) => {
    return callApi({
      method: 'GET',
      url: `/v2/self-contract-info/${params.id}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  customerAgree: (params) => {
    return callApi({
      method: 'POST',
      url: `/v2/public/external-requests/${params.id}/customer-agreed`,
    });
  },
  normalContractUrl: '/v2/normal-contract-details/',
  rentContractsUrl: '/v2/rent-contracts/',
};

// export async function uploadSignatureImage(params) {
//   try {
//     const response = await axios.post(`https://file-uploader.imsform.com/signature`, params.file, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//       responseType: 'json'
//     });
//     return response.data;
//   } catch (error) {
//     alert('실패')
//     return error;
//   }
// }

export async function uploadSignImage(params) {
  return callFileApi({
    method: 'POST',
    url: `/signature`,
    data: params.file,
  });
}
