const DRIVER_TYPE = [
  { label: '선택', value: '-' },
  { label: '1종보통', value: '1종보통' },
  { label: '2종보통', value: '2종보통' },
  { label: '1종대형', value: '1종대형' },
  { label: '대형견인차(트레일러)', value: '대형견인차(트레일러)' },
  { label: '구난차(레커)', value: '구난차(레커)' },
  { label: '소형견인차', value: '소형견인차' },
  { label: '1종소형', value: '1종소형' },
  { label: '2종소형', value: '2종소형' },
  { label: '2종원자', value: '2종원자' },
] as const;

export default DRIVER_TYPE;
