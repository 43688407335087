import dayjs from 'dayjs';

import pad from './pad';

// /^(19|20)\d{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[0-1])$/
// a.test('20200513')

function getShortYear() {
  const fullYear = String(new Date().getFullYear());
  const shortYear = String(fullYear).substr(2, 3);

  return shortYear;
}

export function checkRegIdTemplate(company) {
  const YY = dayjs().format('YY');
  const YYYY = dayjs().format('YYYY');
  const YYYYMM = dayjs().format('YYYYMM');
  const YYYYMMDD = dayjs().format('YYYYMMDD');
  const MM = dayjs().format('MM');
  const DD = dayjs().format('DD');
  let template = '';
  let regExp = null;

  switch (company) {
    case '삼성':
      regExp = /^20[1-9][0-9](0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])-\d{5}$/g;
      template = `${YYYYMMDD}-00001`;
      break;
    default:
      return { message: '접수번호 형식을 확인해주세요.' };
  }

  return {
    message: `접수번호 형식을 확인해주세요. 예) ${template.replace('-', '')}`,
    template,
    regExp,
  };
}

export function insuranceFormatValidate(obj) {
  const value = obj.registration_id.replace('-', '');
  let result = false;
  let regExp;

  switch (obj.insurance_company) {
    case '삼성':
      if (value.length === 0) {
        return true;
      }

      if (value.length > 8) {
        if (regExp.test(value.substr(0, 8))) {
          alert(checkRegIdTemplate('삼성').message);
          result = true;
        }
      }

      if (value.length > 13) {
        alert(checkRegIdTemplate('삼성').message);
        result = true;
      }

      break;
    default:
      result = false;
  }

  return result;
}

export async function regIdParser(param) {
  const registrationId = param.registration_id ? param.registration_id : '';
  const company = param.insurance_company;

  const fullYear = String(new Date().getFullYear());
  const shortYear = String(getShortYear());
  let date = String(new Date().getDate());
  let month = String(new Date().getMonth() + 1);

  if (String(month).length === 1) {
    month = `0${String(month)}`;
  }

  if (String(date).length === 1) {
    date = `0${String(date)}`;
  }

  let result;
  const replaceValue = registrationId.replace('-', '');

  switch (company) {
    case '삼성':
      if (replaceValue.length < 8) {
        return `${fullYear}${month}${date}`;
      }

      if (replaceValue.length > 8) {
        result = replaceValue.substr(0, 8) + pad(replaceValue.substr(8), 5, 0);

        return result;
      }

      break;
    default:
      return registrationId;
  }
}

export function checkRegIdFormat(company) {
  const YY = dayjs().format('YY');
  const YYYY = dayjs().format('YYYY');
  const YYYYMM = dayjs().format('YYYYMM');
  const YYYYMMDD = dayjs().format('YYYYMMDD');
  const MM = dayjs().format('MM');
  const DD = dayjs().format('DD');
  let format = { firstLength: null, secondLength: null, regExp: null, template: null };

  switch (company) {
    case '삼성':
    case '흥국':
      format = {
        firstLength: 8,
        secondLength: 5,
        firstRegx: new RegExp('^(20[1-9][0-9])(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])', 'g'),
        template: `${YYYYMMDD}-12345`,
      };
      break;
    case 'AXA':
    case 'DB':
      format = {
        firstLength: 2,
        secondLength: 8,
        firstRegx: new RegExp('^([1-9][0-9])', 'g'),
        template: `${YY}-12345678`,
      };
      break;
    case '롯데':
    case '한화':
    case '캐롯':
      format = {
        firstLength: 6,
        secondLength: 5,
        firstRegx: new RegExp('^(20[1-9][0-9])(0[1-9]|1[012])', 'g'),
        template: `${YYYYMM}-12345`,
      };
      break;
    case 'KB':
      format = {
        firstLength: 8,
        secondLength: 6,
        firstRegx: new RegExp('^(20[1-9][0-9])(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])', 'g'),
        template: `${YYYYMMDD}-123456`,
      };
      break;
    case '현대':
      format = {
        firstLength: 4,
        secondLength: 6,
        firstRegx: new RegExp('^([1-9][0-9])(0[1-9]|1[012])', 'g'),
        template: `${YY}${MM}-123456`,
      };
      break;
    case '메리츠':
      format = {
        firstLength: 4,
        secondLength: 8,
        firstRegx: new RegExp('^(20[1-9][0-9])', 'g'),
        template: `${YYYY}-12345678`,
      };
      break;
    case 'MG':
      format = {
        firstLength: 4,
        secondLength: 10,
        firstRegx: new RegExp('^(20[1-9][0-9])', 'g'),
        template: `${YYYY}-1234567890`,
      };
      break;
    case '하나':
      format = {
        firstLength: 4,
        secondLength: 7,
        firstRegx: new RegExp('^([1-9][0-9])(0[1-9]|1[012])', 'g'),
        template: `${YY}${MM}-1234567`,
      };
      break;
    case '전세버스공제':
    case '택시공제':
      format = {
        firstLength: 4,
        secondLength: 5,
        firstRegx: new RegExp('^(20[1-9][0-9])', 'g'),
        template: `${YYYY}-12345`,
      };
      break;
    case '렌트카공제':
      format = {
        firstLength: 6,
        secondLength: 4,
        firstRegx: new RegExp('^([1-9][0-9])(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])', 'g'),
        template: `${YY}${MM}${DD}-1234`,
      };
      break;
    default:
      return format;
  }

  return format;
}

export function regIdFormatValidate(company, registrationId) {
  // 접수번호에 대한 계약서 생성 가능 여부 확인
  const format = checkRegIdFormat(company);
  const firstValue = registrationId?.split('-')[0];
  const secondValue = registrationId?.split('-')[1];
  const firstCheck = format.firstRegx?.test(firstValue);
  const secondCheck = secondValue?.length === format.secondLength;
  let result = { status: null, regId: null, message: null };

  /*
      FIXME: 
      1. 앞 뒤 값이 모두 비었으면 null - 계약서 작성 가능
      2. 뒷자리값이 비었으면 null - 계약서 작성 가능
      3. 앞자리값이 날짜 형식에 맞지않으면 alert
      4. 앞자리값 없이 뒷자리만 있을 경우도 alert
      5. 앞자리값에서 년은 2010-2099의 범위로 지정, 월은 01~12 일은 01~31 일의 범위로 지정
    */
  if (registrationId === '-' || !registrationId) {
    // registrationId 값이 없는 경우
    result.status = 'pass';
    result.regId = null;
    result.message = `접수번호 형식을 확인해주세요. 예) ${format.template}`;
  } else {
    // registrationId 값이 있는 경우
    if (isNewHanaRegIdForm(company, registrationId)) {
      if (secondValue?.length < 8) {
        return (result = { status: 'pass', regId: registrationId });
      }

      return (result = {
        status: 'fail',
        message: `접수번호 형식을 확인해주세요.\n예) ${format.template}`,
      });
    }

    if (firstCheck) {
      if (secondValue) {
        if (secondCheck) {
          // firstValue, secondValue 모두 값이 있고 유효성 통과한 경우
          result.status = 'pass';
          result.regId = registrationId;
        } else {
          // firstValue 유효성 통과, secondValue 값이 있으나 유효성 통과 못한 경우
          result.status = 'fail';
          result.message = `접수번호 형식을 확인해주세요. 예) ${format.template}`;
          // result = { status: 'fail', message: `접수번호 형식을 확인해주세요. 예) ${format.template}` }
        }
      } else {
        // firstValue 유효성 통과, secondValue 없는 경우
        result.status = 'pass';
        result.regId = null;
        // result = { status: 'pass', regId: null }
      }
    } else {
      // firstValue가 없거나, firstValue가 있지만 형식이 틀린 경우
      result.status = 'fail';
      result.message = `접수번호 형식을 확인해주세요. 예) ${format.template}`;
    }
  }

  return result;
}

const forSecondValue = (_IdArr) => {
  // 접수번호 `firstValue-secondValue` 중 secondValue를 반환하는 함수
  return _IdArr?.filter((val, idx) => idx !== 0).join('');
};

export const forDividedIdFormat = (registration_id, insurance_company) => {
  const { firstLength, secondLength, template } = checkRegIdFormat(insurance_company);
  const totalLength = firstLength + secondLength;

  if (!registration_id) {
    return { firstId: '', secondId: '', isPass: false, template };
  }

  if (!registration_id?.includes('-')) {
    const firstId = registration_id.substring(0, firstLength);
    const secondId = registration_id.substring(firstLength);
    const isPass = `${firstId}${secondId}`.length === totalLength;

    return { firstId, secondId, isPass, template };
  }

  if (registration_id?.includes('-')) {
    const IdArr = registration_id?.split('-')?.filter((val) => Boolean(val));
    const firstId = IdArr ? IdArr[0] : null;
    const secondId = IdArr ? forSecondValue(IdArr) : null;
    const isPass = `${firstId}${secondId}`.length === totalLength;

    return { firstId, secondId, isPass, template };
  }
};

export function regIdFormatValidateForHana(registrationId) {
  const YYYY = dayjs().format('YYYY');
  const firstValue = registrationId?.split('-')[0];
  const secondValue = registrationId?.split('-')[1];
  const yearRegx = new RegExp('^(20[1-9][0-9])', 'g');
  const yearValue = yearRegx?.test(firstValue);

  if (yearValue) {
    if (!secondValue) {
      return { status: 'pass', regId: null };
    }

    if (secondValue.length < 8) {
      return { status: 'pass', regId: registrationId };
    }

    return { status: 'fail', message: `접수번호 형식을 확인해주세요. 예) ${YYYY}-1234567` };
  }

  return regIdFormatValidate('하나', registrationId);
}

// 보험사 하나(더케이)는 YYMM-1234567, YYYY-1234567 두 개의 접수번호 양식을 사용한다.
// 이중 YYYY-1234567의 경우에는 뒷자리 7개가 모두 채워지지 않아도 계약서 작성이 가능하다.
export function isNewHanaRegIdForm(insuranceCompany, registrationId) {
  const firstValue = registrationId?.split('-')[0];
  const yearRegx = new RegExp('^(20[1-9][0-9])', 'g');
  const isOnlyYear = yearRegx?.test(firstValue);

  return insuranceCompany === '하나' && isOnlyYear;
}
